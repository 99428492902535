const BackgroundLogo = () => {
  return (
    <img
      className="background_logo"
      src="https://i.imgur.com/rNwjdic.png"
      alt="background_logo"
    />
  );
};
export default BackgroundLogo;
